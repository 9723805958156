import React from "react";
import { Button } from "react-bootstrap";
import "./Header.css";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";

function Header({
  title,
  subtitle,
  backgroundUrl,
  color,
  additionalStyling = { fontWeight: "bold" },
}) {
  return (
    <header
      className="cover main-header"
      style={{
        backgroundImage: `url(${getOriginalCloudinaryImageUrl(backgroundUrl)})`,
      }}
    >
      <div className="cover-background main">
        <div className="overlay-main"></div>
        <div className="header-container text-center text-white main">
          <h5
            className="cover-h5 main"
            style={{ color: `${color}`, ...additionalStyling }}
          >
            {title}
          </h5>
          <h2
            className="cover-h2 main"
            style={{ color: `${color}`, ...additionalStyling }}
          >
            {subtitle}
          </h2>
        </div>
      </div>
    </header>
  );
}

export default Header;
