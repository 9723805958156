import { isMobile, isTablet } from "react-device-detect";
import Carousel from "react-bootstrap/Carousel";
import { Card } from "react-bootstrap";
import React, { Component, useEffect } from "react";
import sponsorsList from "./sponsors-list.json";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";
import imagePathSponsorList from "./imagePathSponsor.json";

const MobileSponsor = () => {
  useEffect(() => {
    console.log("isMobile?");
    console.log(isMobile);
  });

  function findBestImageMatch(name, imageAddresses = imagePathSponsorList) {
    const normalizeString = (str) =>
      str
        .toLowerCase()
        .replace(/[^a-zA-Z]+/g, " ")
        .trim();
    const nameWords = normalizeString(name).split(" ");

    const countMatches = (address) => {
      const addressWords = normalizeString(address).split(" ");
      return nameWords.reduce(
        (count, word) => count + (addressWords.includes(word) ? 1 : 0),
        0
      );
    };

    const finalAddress = imageAddresses.reduce((bestMatch, currentAddress) => {
      const bestMatchCount = countMatches(bestMatch);
      const currentCount = countMatches(currentAddress);
      return currentCount > bestMatchCount ? currentAddress : bestMatch;
    }, imageAddresses[0]); // Assume the first address is the best match initially

    const address =
      window.location.host == "localhost:3000"
        ? "/images/winners/past-winners/"
        : "/static/images/winners/past-winners/";

    // console.log(finalAddress);

    return getOriginalCloudinaryImageUrl(finalAddress);
  }

  if (isMobile || isTablet) {
    return (
      <>
        <Carousel>
          {sponsorsList.map((sponsorName) => (
            <Carousel.Item>
              <div>
                <div className="carousel-img-boxy">
                  <Card>
                    {/* <img className="carousel-img-box" src={sponsor3} /> */}
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />
                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </>
    );
  } else {
    return (
      <>
        <Carousel>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(0, 5).map((sponsorName) => (
                  <Card>
                    {sponsorName.includes("Hifz") ? (
                      <img
                        className="carousel-img-box"
                        src={getOriginalCloudinaryImageUrl("Hifzacademy.png")}
                      />
                    ) : (
                      <img
                        className="carousel-img-box"
                        src={findBestImageMatch(sponsorName)}
                      />
                    )}
                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(5, 10).map((sponsorName) => (
                  <Card>
                    {sponsorName.includes("Maimoona") ? (
                      <img
                        className="carousel-img-box"
                        src={getOriginalCloudinaryImageUrl(
                          "MaimoonaWasiAhmed.jpeg"
                        )}
                      />
                    ) : (
                      <img
                        className="carousel-img-box"
                        src={findBestImageMatch(sponsorName)}
                      />
                    )}

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(10, 15).map((sponsorName) => (
                  <Card>
                    {sponsorName.includes("Rashida Abdullah") ? (
                      <img
                        className="carousel-img-box"
                        src={getOriginalCloudinaryImageUrl(
                          "RashidaAbdullah.jpeg"
                        )}
                      />
                    ) : (
                      <img
                        className="carousel-img-box"
                        src={findBestImageMatch(sponsorName)}
                      />
                    )}

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(15, 20).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(20, 25).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(25, 30).map((sponsorName) => (
                  <Card>
                    {sponsorName.includes("Safari") ? (
                      <img
                        className="carousel-img-box"
                        src={getOriginalCloudinaryImageUrl(
                          "HudHud-Scholarship-For-Somali-Canadian-Youth.png"
                        )}
                      />
                    ) : (
                      <img
                        className="carousel-img-box"
                        src={findBestImageMatch(sponsorName)}
                      />
                    )}

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(30, 35).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(35, 40).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(40, 45).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(45, 50).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <div className="carousel-img-boxy">
                {sponsorsList.slice(50, 55).map((sponsorName) => (
                  <Card>
                    <img
                      className="carousel-img-box"
                      src={findBestImageMatch(sponsorName)}
                    />

                    <span className="carousel-text-box">{sponsorName}</span>
                  </Card>
                ))}
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
};
export default MobileSponsor;
