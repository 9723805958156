import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Footer.css";
import { MailChimp } from "./MailChimp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="container" style={{ background: "#0260A8" }}>
      <footer className="py-5">
        <div className="row">
          <div
            className="col-6 col-md-2 mb-3"
            style={{ width: "30%", marginRight: "2em" }}
          >
            <h5 style={{ paddingBottom: "1em" }}>About MSF</h5>
            <p>
              The MAX Scholarships Fund (MSF) awards well-rounded High School
              and Post Secondary students who are eager to make an impact, learn
              and create.
            </p>
            <p>
              {" "}
              They are students who have contributed to their communities and
              Canada at large, achieved above-average marks and are recognized
              by their peers, Muslim and non-Muslim alike.
            </p>
          </div>

          <div className="col-6 col-md-2 mb-3">
            <h5 style={{ paddingBottom: "1em" }}>Helpful Links</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="/scholarships"
                  className="nav-link p-0 text-body-secondary"
                >
                  Scholarships
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/sponsor" className="nav-link p-0 text-body-secondary">
                  Sponsor
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/past-winners"
                  className="nav-link p-0 text-body-secondary"
                >
                  Past Winners
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/financials"
                  className="nav-link p-0 text-body-secondary"
                >
                  Financials
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/privacy-policy"
                  className="nav-link p-0 text-body-secondary"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/faq" className="nav-link p-0 text-body-secondary">
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          <div
            className="col-md-5 offset-md-1 mb-3"
            style={{ marginLeft: "3em" }}
          >
            <h5 style={{ paddingBottom: "1em" }}>
              Subscribe to our newsletter
            </h5>
            <MailChimp />
            <div className="footer-icons">
              <a
                href="https://www.instagram.com/max.scholarshipfund/?hl=en"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "#ffffff", height: "50px" }}
                />
              </a>
              <a
                href="https://www.facebook.com/max.scholarshipfund/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  style={{ color: "#ffffff", height: "50px" }}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
          <p>© 2024 MAX, Inc. All rights reserved.</p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3">
              <a className="link-body-emphasis" href="#">
                <svg className="bi" width="24" height="24">
                  {/*<use xlink:href="#twitter"></use>*/}
                </svg>
              </a>
            </li>
            <li className="ms-3">
              <a className="link-body-emphasis" href="#">
                <svg className="bi" width="24" height="24">
                  {/*<use xlink:href="#instagram"></use>*/}
                </svg>
              </a>
            </li>
            <li className="ms-3">
              <a className="link-body-emphasis" href="#">
                <svg className="bi" width="24" height="24">
                  {/*<use xlink:href="#facebook"></use>*/}
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
