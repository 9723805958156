import React, { Component, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserView, MobileView } from "react-device-detect";
import { isMobile } from "react-device-detect";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import {
  faGraduationCap,
  faTrophy,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import "./Landing.css";
import { Card, Button, Image } from "react-bootstrap";
import MobileSponsor from "./sponsors-carousel";
import HeaderHome from "../../components/HeaderHome/HeaderHome";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";
import Header from "../../components/Header/Header";

const Landing = () => {
  const cloudImagePathNames = [
    "landing-photo-20.jpeg",
    "landing-photo-1.jpg",
    "landing-photo-2.jpg",
    "landing-photo-3.jpg",
    "landing-photo-4.jpg",
    "landing-photo-5.jpg",
    "landing-photo-6.jpg",
    "landing-photo-7.jpg",
    "landing-photo-8.jpg",
    "landing-photo-9.jpg",
    "landing-photo-10.jpg",
    "landing-photo-11.jpg",
    "landing-photo-12.jpg",
    "landing-photo-13.jpg",
    "landing-photo-14.png",
    "landing-photo-15.png",
    "landing-photo-16.png",
    "landing-photo-17.png",
    "landing-photo-18.png",
    "landing-photo-19.png",
  ];

  useEffect(() => {
    console.log(getOriginalCloudinaryImageUrl("landing-photo-1.jpg"));
  }, []);

  return (
    <div className="home">
      {/* <HeaderHome /> */}
      <Header title="" backgroundUrl="Homepage.jpeg" />
      <div className="about-section">
        <div className="about-msf">
          <iframe
            // style={{ width: "47.5%" }}
            // width="560"
            height="315"
            src="https://www.youtube.com/embed/NhG8rSA_nu4?si=bbGZtjppeV5FHm17"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="about-msf-section">
            <h2 style={{ color: "#0260A8", fontWeight: "bold" }}>
              {" "}
              About MSF{" "}
            </h2>
            <p style={{ color: "#808080", fontSize: "20px" }}>
              {" "}
              The MAX Scholarships Fund (MSF) awards well-rounded High School
              and University students who are eager to make an impact, learn and
              create. They are students who have contributed to their
              communities and Canada at large, achieved above-average marks and
              are recognized by their peers, Muslim and non Muslim alike. MSF is
              a registered charitable entity, and donors are eligible for a
              Charitable Tax Receipt.{" "}
            </p>
            <h5
              style={{
                color: "#0260A8",
                fontWeight: "bold",
              }}
            >
              {" "}
              The 2024 Scholarship Cycle is expected to open on Feb 26, 2024 @
              12:00 AM America/Toronto{" "}
            </h5>
            {/* <a href="/scholarships" style={{ textDecoration: "none" }}>
              <button className="clear-button">
                {" "}
                Apply for a scholarship{" "}
              </button>
            </a> */}
          </div>
        </div>

        <div className="about-two-button">
          <a href="/sponsor" style={{ textDecoration: "none" }}>
            <button className="about-two-buttons"> Become a Sponsor </button>
          </a>
          <a href="/scholarships" style={{ textDecoration: "none" }}>
            <button className="about-two-buttons">
              {" "}
              View our Scholarships{" "}
            </button>
          </a>
          <a href="/scholarships" style={{ textDecoration: "none" }}>
            <button className="about-two-buttons">
              {" "}
              Apply for a Scholarship{" "}
            </button>
          </a>
        </div>
        <div className="about-sponsor">
          <h2
            style={{
              color: "#0260A8",
              paddingBottom: "1em",
              fontWeight: "bold",
            }}
          >
            {" "}
            Sponsor Highlights{" "}
          </h2>
          <div className="about-sponsors">
            <div className="sponsor-list">
              <MobileSponsor />
            </div>
          </div>
        </div>
      </div>
      <div className="about-highlights">
        <h2
          style={{
            color: "white",
            fontSize: "36px",
            fontWeight: "bold",
            paddingBottom: "1.5em",
          }}
        >
          Since 2016 MSF has...
        </h2>
        <div className="about-highlight-boxes">
          <div className="about-highlight-box">
            <FontAwesomeIcon
              icon={faGraduationCap}
              style={{ color: "#ffffff", height: "60px" }}
            />
            <p className="p-highlight">Awarded in scholarships</p>
            <h2 className="h2-highlight">$1.4 Million</h2>
            {/* <h2 className="h2-highlight">$798,000</h2> */}
          </div>
          <div className="about-highlight-box">
            <FontAwesomeIcon
              icon={faTrophy}
              style={{ color: "#ffffff", height: "60px" }}
            />
            <p className="p-highlight">Scholarships Awarded</p>
            <h2 className="h2-highlight">350+</h2>
          </div>
          <div className="about-highlight-box">
            <FontAwesomeIcon
              icon={faStar}
              style={{ color: "#ffffff", height: "60px" }}
            />
            <p className="p-highlight">Scholarships Sponsors</p>
            <h2 className="h2-highlight">90+</h2>
          </div>
        </div>
      </div>
      <div className="about-winners">
        <h2
          style={{
            color: "#0260A8",
            paddingBottom: "1em",
            fontWeight: "bold",
          }}
        >
          {" "}
          A glimpse through time...{" "}
        </h2>
        <Carousel>
          {cloudImagePathNames.map((path) => (
            <Carousel.Item>
              <img
                className="winner-img-box"
                src={getOriginalCloudinaryImageUrl(path) || ""}
                style={{ width: "90%" }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="landing-card">
        {/* <h2 style={{ color: "#0260A8", fontSize: "30px", fontWeight: "bold" }}>
          Impact Report
        </h2> */}
        <h2
          style={{
            color: "#0260A8",
            paddingBottom: "1em",
            fontWeight: "bold",
          }}
        >
          {" "}
          Impact Report{" "}
        </h2>
        <div className="landing-card-impact">
          <a
            href={
              "https://maxscholars.org/media/financial_statements/MSF_Impact_Report_2023_Digital.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card key={0} className="pdf-card" style={{ width: "100%" }}>
              <Image
                // src={impactreport}
                src={getOriginalCloudinaryImageUrl("impact.png")}
                alt={"MSF Impact Report"}
                fluid
                rounded
                style={{
                  width: "100%",
                  height: "auto%" /* or set a specific height */,
                }}
              />
            </Card>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landing;
