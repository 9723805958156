import "./Scholarships.css";
import React, { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Tab,
  Card,
  Button,
  Row,
  Col,
  Accordion,
  Modal,
} from "react-bootstrap";
import Header from "../../components/Header/Header";
import {
  getScholarships,
  getSponsors,
  getCurrentScholarshipsCycle,
  getScholarshipDetails,
} from "../../utils/BackendService";
import scholarshipDetails from "./scholarships-data.json";
import { Link, useLocation } from "react-router-dom";
import sponsorImagesAddresses from "../ScholarshipDetail/sponsor-images-address.json";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";
import { findClosestMatch } from "../../utils/StringUtils";

const Scholarships = () => {
  const [key, setKey] = useState("HighSchool");

  const [currentCycleData, setCurrentCycleData] = useState({});

  const [scholarshipData, setScholarshipData] = useState([]);

  const [selectedScholarship, setSelectedScholarship] = useState({
    id: 1,
    name: "Abdulkarim Muhaseen",
    scholarship_name:
      "Mohammad Humayun Zafar Scholarship for Sciences and Engineering",
    biography:
      "Abdulkarim grew up in humble beginnings which largely shaped his drive. During his undergraduate degree in Medical Physics, he held office for several years as president of his program's student union, was involved in fund raising money for global educational initiatives and wrote on social justice issues in a local community publication.  Abdulkarim is currently at U of T medical school, and is intending to pursue a career in global health and health journalism. In his free time you will likely find him practicing gymnastics or playing basketball, trying to speak a foreign language or experimenting in the kitchen to his mother's disapproval.",
    photo:
      "scholarship_winners_pictures/2016_-_Abdulkarim_Muhaseen_2-cropped-1.png",
    year: 2016,
  });

  const [show, setShow] = useState(false);

  const postSecondaryTab = useRef();
  const highSchoolTab = useRef();

  const formatDollar = (num) => {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      maximumSignificantDigits: 3,
    }).format(num);
  };

  const handleClose = () => setShow(false);
  const handleShow = async (id) => {
    // This function fetches the details of an individual scholarship based on the name
    // console.log(scholarshipDetails);
    const scholarShip = await getScholarshipDetails(id);
    // console.log(scholarShip);
    setSelectedScholarship(scholarShip);
    setShow(true);
  };

  useEffect(() => {
    getCurrentCycleData();
    getScholarships().then((data) => setScholarshipData(data));
  }, []);

  const getSubCategories = () => {
    return [...new Set(scholarshipData.map((x) => x.subcategory_name))];
  };

  const setKeyOption = (tabKey) => {
    setKey(tabKey);
    if (tabKey == "Undergraduate") {
      postSecondaryTab.current.classList.add("key");
      highSchoolTab.current.classList.remove("key");
    } else {
      postSecondaryTab.current.classList.remove("key");
      highSchoolTab.current.classList.add("key");
    }
  };

  const removeDuplicates = (array) => {
    return array.reduce((accumulator, current) => {
      if (!accumulator.some((obj) => obj.name == current.name)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  };

  function formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const getPSDataCount = (subCategory) => {
    return getPostSecondaryData().filter(
      (s) => s.subcategory_name === subCategory
    ).length;
  };

  const getHSDataCount = (subCategory) => {
    return getHighSchoolData().filter((s) => s.subcategory_name == subCategory)
      .length;
  };

  const getPostSecondaryData = () => {
    let postData = scholarshipData.filter(
      (s) => s.level_of_education == "Undergraduate"
    );
    // console.log("removed duplicates");
    // console.log(removeDuplicates(postData));
    return removeDuplicates(postData);
  };
  const getHighSchoolData = () => {
    let HSData = scholarshipData.filter(
      (s) => s.level_of_education == "High School"
    );
    return removeDuplicates(HSData);
  };

  const getCurrentCycleData = async () => {
    const currentCycleData = await getCurrentScholarshipsCycle();
    setCurrentCycleData(currentCycleData);
  };

  return (
    <div className="my-component">
      <Header title="" backgroundUrl="Homepage.jpeg" />
      <div className="main-div">
        <div className="scholarship-info text-center my-4">
          <h1
            style={{
              color: "#0260A8",
              paddingBottom: "1em",
              fontWeight: "bold",
            }}
          >
            {" "}
            Scholarships{" "}
          </h1>
          <p>
            The objective of the MAX Scholarship Fund is to recognize and reward
            High School and Post Secondary students who have excelled in
            academics and combined this achievement with outstanding
            contributions to their school and/or community.
          </p>
          {currentCycleData ? (
            <p>
              Applications for the {currentCycleData.year} Scholarship Cycle
              closed on {formatDate(currentCycleData.application_deadline_date)}{" "}
              @ 2:59 AM America/Toronto. The next scholarship cycle will be
              announced at a later time.
            </p>
          ) : null}
        </div>
        <Row className="mb-4 app-row-sch">
          <Col className="text-center apply-btns scholar-app-btn-wrapper">
            <Link to="/applicant-consent" target="_blank">
              <Button
                variant="primary app-btn"
                className="mx-2 scholar-app-btn"
                value="Applicant Consent"
              >
                Applicant Consent
              </Button>
            </Link>
            <a href="/public/login" target="_blank">
              <Button
                variant="primary app-btn"
                className="mx-2 scholar-app-btn"
                value="Apply for a scholarship"
              >
                Apply for a scholarship
              </Button>
            </a>
          </Col>
        </Row>

        <div className="scholarships-list-container">
          <div className="tabs-container">
            <div
              className="tab highschool-tab key"
              onClick={() => setKeyOption("HighSchool")}
              ref={highSchoolTab}
            >
              High School Scholarships
            </div>
            <div
              className="tab post-secondary-tab"
              onClick={() => setKeyOption("Undergraduate")}
              ref={postSecondaryTab}
            >
              Post Secondary Scholarships
            </div>
          </div>
          {scholarshipData
            ? // ? getSubCategories().map((sub, i) => (
              (key == "Undergraduate"
                ? [
                    ...new Set(
                      getPostSecondaryData().map((x) => x.subcategory_name)
                    ),
                  ]
                : [
                    ...new Set(
                      getHighSchoolData().map((x) => x.subcategory_name)
                    ),
                  ]
              ).map(
                // [...new Set(scholarshipData.map((x) => x.subcategory_name))].map(
                (sub, i) => (
                  <>
                    <Accordion>
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>
                          {sub} -{" "}
                          {key == "Undergraduate"
                            ? getPSDataCount(sub)
                            : getHSDataCount(sub)}{" "}
                          Scholarships
                        </Accordion.Header>
                        <Accordion.Body>
                          {key == "Undergraduate"
                            ? getPostSecondaryData()
                                .filter((s) => s.subcategory_name === sub)
                                .map((s) => (
                                  <>
                                    <div className="scholarship-name-wrapper">
                                      <p
                                        key={i}
                                        onClick={() => handleShow(s.id)}
                                      >
                                        {s.name} -{" "}
                                        {formatDollar(s.award_amount)}
                                      </p>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="learn-more-btn"
                                        onClick={() => handleShow(s.id)}
                                      >
                                        Learn more
                                      </Button>
                                    </div>
                                  </>
                                ))
                            : getHighSchoolData()
                                .filter((s) => s.subcategory_name == sub)
                                .map((s) => (
                                  <>
                                    <div className="scholarship-name-wrapper">
                                      <p
                                        key={i}
                                        onClick={() => handleShow(s.id)}
                                      >
                                        {s.name} -{" "}
                                        {formatDollar(s.award_amount)}
                                      </p>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="learn-more-btn"
                                        onClick={() => handleShow(s.id)}
                                      >
                                        Learn more
                                      </Button>
                                    </div>
                                  </>
                                ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )
              )
            : null}

          {selectedScholarship.name ? (
            <Modal
              show={show}
              onHide={handleClose}
              className="winner-modal-box"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <p className="winner-modal-name">
                    {selectedScholarship.name}
                  </p>
                  <p className="winner-modal-scholarship">
                    Award Amount -{" "}
                    {formatDollar(selectedScholarship.award_amount)}
                  </p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="winner-modal-container">
                  <img
                    className="winner-modal-img"
                    src={findClosestMatch(
                      selectedScholarship.name,
                      sponsorImagesAddresses
                    )}
                  />
                  <p className="winner-modal-bio">
                    {selectedScholarship.description}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link to={`/scholarships/${selectedScholarship.id}`}>
                  <Button
                    variant="primary"
                    onClick={handleClose}
                    className="scholarships-open-btn"
                  >
                    Open in New Window
                  </Button>
                </Link>
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Scholarships;
