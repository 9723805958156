import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  getScholarshipDetails,
  getScholarships,
} from "../../utils/BackendService";
import scholarshipDetails from "../Scholarships/scholarships-data.json";
import "./ScholarshipDetail.css";
import sponsorImagesAddresses from "./sponsor-images-address.json";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";
import { findClosestMatch } from "../../utils/StringUtils";

function ScholarshipDetail() {
  const [scholarshipData, setScholarshipData] = useState([]);
  const [scholarshipDetail, setScholarshipDetail] = useState({
    fields: { name: "" },
  });
  const location = useLocation();
  let { pk } = useParams();
  const [pkey, setPk] = useState({ pk });

  const fetchScholarship = async () => {
    getScholarshipDetails(pk)
      .then((res) => {
        setScholarshipDetail(res);
      })
      .catch((err) => console.log(err));
  };

  const formatDollar = (num) => {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      maximumSignificantDigits: 3,
    }).format(num);
  };

  useEffect(() => {
    setScholarshipDetail({});
    fetchScholarship();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header title="" backgroundUrl="Sponsor.png" color="white" />
      <div style={{ textAlign: "center", marginTop: "3em" }}>
        <h1
          style={{
            color: "#0260A8",
            fontWeight: "bold",
          }}
        >
          {" "}
          {scholarshipDetail?.name || "Scholarship Details"}{" "}
        </h1>
      </div>
      {scholarshipDetail ? (
        <>
          <div className="scholarship-detail-main-container">
            <div className="back-btn-container">
              <Link to="/scholarships">
                <button className="scholarship-detail-back-btn">Go Back</button>
              </Link>
            </div>
            <div className="scholarship-detail-header">
              <h3 className="scholarship-detail-header-name">
                {scholarshipDetail.name}
              </h3>
              <h3 className="scholarship-detail-header-amount">
                {formatDollar(scholarshipDetail.award_amount)}
              </h3>
            </div>
            <div className="scholarship-detail-body">
              <div className="scholarship-detail-body-left-pane">
                <img
                  src={findClosestMatch(
                    scholarshipDetail?.name,
                    sponsorImagesAddresses
                  )}
                  className="winner-modal-img"
                />
              </div>
              <div className="scholarship-detail-body-right-pane">
                <p>{scholarshipDetail.description}</p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ScholarshipDetail;
