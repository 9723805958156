import sponsorImagesAddresses from "../pages/ScholarshipDetail/sponsor-images-address.json";
import { getOriginalCloudinaryImageUrl } from "./CloudinaryService";

export const findClosestMatch = (
  inputString = "general",
  stringArray = sponsorImagesAddresses
) => {
  // Normalize the input string: lowercase and replace non-alphanumerics with dashes
  const normalizedInput = inputString
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, "-");

  let maxMatchCount = 0;
  let closestMatch = "";

  stringArray.forEach((item) => {
    // Normalize array item
    const normalizedItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-");

    // Count matching segments
    let matchCount = 0;
    normalizedInput.split("-").forEach((segment) => {
      if (normalizedItem.includes(segment) && segment.length > 0) {
        matchCount++;
      }
    });

    // Update closest match if current item has more matches
    if (matchCount > maxMatchCount) {
      maxMatchCount = matchCount;
      closestMatch = item;
    }
  });

  //   console.log(closestMatch);
  // return closestMatch;
  return getOriginalCloudinaryImageUrl(closestMatch);
};
